import { css } from "@emotion/react"
import camelCase from "lodash/camelCase"
import { useRef, useState } from "react"
import FrameLayout from "../components/FrameLayout"
import Header from "../components/Header"
import Loader from "../components/Loader"
import SEO from "../components/Seo"

const FramedPageTemplate = ({ pageContext }) => {
  const { langKey, name, url, scale = 1 } = pageContext
  const [loading, setLoading] = useState(true)
  const _langKey = camelCase(langKey)
  const iframe = useRef()

  const frame = url[_langKey].trim().startsWith("/")
    ? url[_langKey].trim()
    : `/${url[_langKey].trim()}`

  return (
    <FrameLayout>
      <SEO title={name[_langKey]} />
      <Header langKey={langKey} />
      {loading && <Loader />}
      <iframe
        ref={iframe}
        onLoad={() => setLoading(false)}
        name="main"
        className="scrollable"
        src={`https://www.kidslandchina.com${frame}`}
        frameBorder={0}
        height={"100%"}
        width={"100%"}
        css={css`
          @media only screen and (max-device-width: 1024px) {
            -ms-zoom: ${scale};
            -moz-transform: scale(${scale});
            -moz-transform-origin: 0 0;
            -o-transform: scale(${scale});
            -o-transform-origin: 0 0;
            -webkit-transform: scale(${scale});
            -webkit-transform-origin: 0 0;
          }
        `}
      />
    </FrameLayout>
  )
}

export default FramedPageTemplate
