/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { css, Global } from "@emotion/react"
import React from "react"

const FrameLayout = ({ children, ...rest }) => (
  <>
    <Global
      styles={css`
        html,
        body {
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
        }

        html {
          overflow: hidden;
        }

        body {
          overflow: auto;
          font-family: 微软雅黑;
        }

        iframe {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        * {
          box-sizing: border-box;
        }
      `}
    />
    <main>{children}</main>
  </>
)

export default FrameLayout
