import { css } from "@emotion/react"
import { graphql, useStaticQuery } from "gatsby"
import { theme } from "../utils/theme"

const Loader = () => {
  const { sanitySiteSettings } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        color {
          hex
        }
      }
    }
  `)

  const color = sanitySiteSettings.color.hex || theme.colors.primary

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .lds-grid {
          display: inline-block;
          position: relative;
          width: 64px;
          height: 64px;
        }
        .lds-grid div {
          position: absolute;
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background: ${color};
          animation: lds-grid 1.2s linear infinite;
        }
        .lds-grid div:nth-of-type(1) {
          top: 6px;
          left: 6px;
          animation-delay: 0s;
        }
        .lds-grid div:nth-of-type(2) {
          top: 6px;
          left: 26px;
          animation-delay: -0.4s;
        }
        .lds-grid div:nth-of-type(3) {
          top: 6px;
          left: 45px;
          animation-delay: -0.8s;
        }
        .lds-grid div:nth-of-type(4) {
          top: 26px;
          left: 6px;
          animation-delay: -0.4s;
        }
        .lds-grid div:nth-of-type(5) {
          top: 26px;
          left: 26px;
          animation-delay: -0.8s;
        }
        .lds-grid div:nth-of-type(6) {
          top: 26px;
          left: 45px;
          animation-delay: -1.2s;
        }
        .lds-grid div:nth-of-type(7) {
          top: 45px;
          left: 6px;
          animation-delay: -0.8s;
        }
        .lds-grid div:nth-of-type(8) {
          top: 45px;
          left: 26px;
          animation-delay: -1.2s;
        }
        .lds-grid div:nth-of-type(9) {
          top: 45px;
          left: 45px;
          animation-delay: -1.6s;
        }
        @keyframes lds-grid {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
        }
      `}
    >
      <div className="lds-grid">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
